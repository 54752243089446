<template>
  <div id="edit-order">
    <order-breadcrumbs
      :order="order"
      :meta="meta"
    />

    <validation-observer
      ref="editOrderValidation"
    >
      <form
        id="editOrderForm"
        @submit.prevent
      >
        <b-row class="justify-content-center">

          <b-col
            cols="12"
            md="7"
          >

            <b-row class="justify-content-between h-100">
              <b-col cols="12">

                <b-card
                  :title="$t('order.label.base-order-info.title')"
                  class="card-justify"
                >
                  <b-card-text>
                    <rc-overlay :show="loading">
                      <b-row>
                        <b-col
                          cols="12"
                          class="pb-1"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('order.label.type')"
                            :rules="fullValidation ? 'required' : ''"
                          >
                            <b-form-radio-group
                              v-model="order.type"
                              name="orderType"
                              @change="commitOrder()"
                            >
                              <b-form-radio
                                v-for="orderType in orderTypes"
                                :key="orderType"
                                v-model="order.type"
                                :value="orderType"
                                :disabled="order.status !== 'draft'"
                              >
                                {{ $t(`order.types.${orderType}`) }}
                              </b-form-radio>
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col
                          cols="12"
                          class="pb-1"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('order.label.base-order-info.transport-at')"
                            rules="required"
                          >
                            <label for="transportAt">
                              {{ $t('order.label.base-order-info.transport-at') }}
                            </label>
                            <flat-pickr
                              id="transportAt"
                              v-model="order.transportAt"
                              class="form-control"
                              :config="configDateTimePicker"
                              :label="$t('order.label.base-order-info.transport-at')"
                              :disabled="isDisabled(statusesWhenDispatcherCanEdit, ['ROLE_DISPATCHER'])"
                              @change="commitOrder()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col
                          v-show="!order.private"
                          cols="6"
                          class="pb-1"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('order.label.base-order-info.commissioned-by')"
                            rules="max:50"
                          >
                            <label for="commissionedBy">
                              {{ $t('order.label.base-order-info.commissioned-by') }}
                            </label>
                            <b-form-input
                              id="commissionedBy"
                              v-model="order.commissionedBy"
                              type="text"
                              maxlength="50"
                              :placeholder="$t('order.label.base-order-info.commissioned-by')"
                              autocomplete="off"
                              :disabled="isDisabled(statusesWhenDispatcherCanEdit, ['ROLE_DISPATCHER'])"
                              @change="commitOrder()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col
                          v-show="!order.private"
                          cols="6"
                          class="pb-1"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('order.label.base-order-info.agreed-with')"
                            rules="max:50"
                          >
                            <label for="agreedWith">
                              {{ $t('order.label.base-order-info.agreed-with') }}
                            </label>
                            <b-form-input
                              id="agreedWith"
                              v-model="order.agreedWith"
                              type="text"
                              maxlength="50"
                              :placeholder="$t('order.label.base-order-info.agreed-with')"
                              autocomplete="off"
                              :disabled="isDisabled(statusesWhenDispatcherCanEdit, ['ROLE_DISPATCHER'])"
                              @change="commitOrder()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col
                          cols="12"
                          class="pb-50"
                        >
                          <b-form-checkbox
                            v-model="order.prioritySignals"
                            @change="commitOrder()"
                          >
                            {{ $t('order.label.base-order-info.priority-signals') }}
                          </b-form-checkbox>
                        </b-col>

                        <b-col
                          cols="12"
                          class="pb-50"
                        >
                          <b-form-checkbox
                            v-model="order.returnTransport"
                            :disabled="isDisabled(statusesWhenDispatcherCanEdit, ['ROLE_DISPATCHER'])"
                            @change="commitOrder()"
                          >
                            {{ $t('order.label.base-order-info.return-transport') }}
                          </b-form-checkbox>
                        </b-col>

                        <b-col
                          cols="12"
                          class="pb-50"
                        >
                          <b-form-checkbox
                            v-model="order.covidPositive"
                            :disabled="isDisabled(statusesWhenDispatcherCanEdit, ['ROLE_DISPATCHER'])"
                            @change="commitOrder()"
                          >
                            {{ $t('order.label.base-order-info.covid') }}
                          </b-form-checkbox>
                        </b-col>

                        <b-col
                          cols="12"
                          class="pb-50"
                        >
                          <b-form-checkbox
                            v-model="differentClientInfo"
                            :disabled="order.private || isDisabled(statusesWhenDispatcherCanEdit, ['ROLE_DISPATCHER'])"
                            @change="handleDifferentClientInfo"
                          >
                            {{ $t('order.label.transport-info.set-different-client-data') }}
                          </b-form-checkbox>
                        </b-col>

                        <b-col
                          cols="12"
                          class="pb-50"
                        >
                          <b-form-checkbox
                            v-model="order.private"
                            :disabled="isDisabled()"
                            @change="handleIsPrivate();"
                          >
                            {{ $t('order.label.base-order-info.private') }}
                          </b-form-checkbox>
                        </b-col>

                        <b-col
                          v-if="order.private"
                          cols="6"
                          class="pb-1"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('order.label.base-order-info.amount-gross')"
                            :rules="fullValidation ? 'required|min_value:1|max:50' : 'max:50'"
                          >
                            <label for="paymentAmountGross">
                              {{ $t('order.label.base-order-info.amount-gross') }}
                            </label>
                            <b-form-input
                              id="paymentAmountGross"
                              v-model="order.amountGross"
                              type="number"
                              maxlength="50"
                              :placeholder="$t('order.label.base-order-info.amount-gross')"
                              autocomplete="off"
                              :disabled="isDisabled(statusesWhenDispatcherCanEdit, ['ROLE_DISPATCHER'])"
                              @change="commitOrder"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col
                          v-if="order.private"
                          cols="6"
                          class="pb-1"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('order.label.base-order-info.payment-method')"
                            :rules="fullValidation ? 'required' : ''"
                          >
                            <b-form-group>
                              <label for="paymentMethod">
                                {{ $t('order.label.base-order-info.payment-method') }}
                              </label>
                              <v-select
                                id="paymentMethod"
                                v-model="order.paymentMethod"
                                :placeholder="$t('order.label.base-order-info.payment-method')"
                                :options="paymentMethods"
                                :clearable="false"
                                :searchable="false"
                                :disabled="isDisabled(statusesWhenDispatcherCanEdit, ['ROLE_DISPATCHER'])"
                                :reduce="option => option.value"
                                @change="commitOrder"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>

                          </validation-provider>

                        </b-col>

                      </b-row>
                    </rc-overlay>
                  </b-card-text>

                </b-card>
              </b-col>

              <b-col cols="12">
                <b-card
                  footer-tag="footer"
                  :title="$t('order.patient-info.title')"
                  class="card-justify"
                >
                  <b-card-text>
                    <rc-overlay :show="loading">
                      <b-row>

                        <b-col
                          cols="6"
                          class="pb-1"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('order.patient-info.surname')"
                            :rules="fullValidation ? 'required|max:50' : 'max:50'"
                          >
                            <label for="patientInfoSurname">
                              {{ $t('order.patient-info.surname') }}
                            </label>
                            <b-form-input
                              id="patientInfoSurname"
                              v-model="order.patientInfo.surname"
                              type="text"
                              maxlength="50"
                              :placeholder="$t('order.patient-info.surname')"
                              autocomplete="off"
                              :disabled="isDisabled(statusesWhenDispatcherCanEdit, ['ROLE_DISPATCHER'])"
                              @change="commitOrder()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col
                          cols="6"
                          class="pb-1"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('order.patient-info.name')"
                            :rules="fullValidation ? 'required|max:50' : 'max:50'"
                          >
                            <label for="patientInfoName">
                              {{ $t('order.patient-info.name') }}
                            </label>
                            <b-form-input
                              id="patientInfoName"
                              v-model="order.patientInfo.name"
                              type="text"
                              maxlength="50"
                              :placeholder="$t('order.patient-info.name')"
                              autocomplete="off"
                              :disabled="isDisabled(statusesWhenDispatcherCanEdit, ['ROLE_DISPATCHER'])"
                              @change="commitOrder()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col
                          cols="12"
                          class="pb-1"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('order.patient-info.pesel')"
                            :rules="fullValidation ? 'digits:11' : 'digits:11'"
                          >
                            <label for="patientInfoName">
                              {{ $t('order.patient-info.pesel') }}
                            </label>

                            <b-form-input
                              v-model="order.patientInfo.pesel"
                              type="number"
                              :name="$t('order.patient-info.pesel')"
                              @change="commitOrder"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col
                          cols="12"
                          md="6"
                          lg="3"
                          class="pb-1"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('order.patient-info.age-in-years')"
                            :rules="fullValidation ? 'required|max_value:120' : 'max_value:120'"
                          >
                            <label for="patientInfoAge">
                              {{ $t('order.patient-info.age-in-years') }}
                            </label>
                            <b-input-group>
                              <b-form-input
                                id="patientInfoAge"
                                v-model="order.patientInfo.age"
                                type="number"
                                maxlength="3"
                                :placeholder="$t('order.patient-info.age-in-years')"
                                autocomplete="off"
                                :disabled="isDisabled(statusesWhenDispatcherCanEdit, ['ROLE_DISPATCHER'])"
                                @change="commitOrder()"
                              />
                              <b-input-group-append>
                                <b-button
                                  class="px-75"
                                  variant="outline-secondary"
                                  @click="convertFromAnnual"
                                >
                                  <feather-icon icon="RefreshCwIcon" />
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col
                          cols="12"
                          md="6"
                          lg="3"
                          class="pb-1"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('order.patient-info.weight')"
                            :rules="fullValidation ? 'max_value:500' : ''"
                          >
                            <label for="patientInfoWeight">
                              {{ $t('order.patient-info.weight') }}
                            </label>
                            <b-input-group>
                              <b-form-input
                                id="patientInfoWeight"
                                v-model="order.patientInfo.weight"
                                type="number"
                                maxlength="3"
                                :placeholder="$t('order.patient-info.weight')"
                                autocomplete="off"
                                :disabled="isDisabled(statusesWhenDispatcherCanEdit, ['ROLE_DISPATCHER'])"
                                @change="commitOrder()"
                              />
                              <b-input-group-append is-text>
                                kg
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col
                          cols="12"
                          lg="6"
                          class="pb-1"
                        >
                          <label>
                            {{ $t('order.patient-info.sex') }}
                          </label>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('order.label.sex')"
                            :rules="fullValidation ? 'required' : ''"
                          >
                            <b-form-radio-group
                              v-model="order.patientInfo.sex"
                              name="patientInfoSex"
                              @change="commitOrder()"
                            >

                              <b-form-radio
                                value="M"
                                :disabled="isDisabled()"
                                class="mt-50"
                              >
                                {{ $t('order.patient-info.male') }}
                              </b-form-radio>
                              <b-form-radio
                                value="F"
                                :disabled="isDisabled()"
                                class="mt-50"
                              >
                                {{ $t('order.patient-info.female') }}
                              </b-form-radio>
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col
                          cols="12"
                          class="pb-1"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('order.label.comment')"
                            :rules="fullValidation ? 'required|max:10000' : 'max:10000'"
                          >
                            <label>
                              {{ $t('order.label.comment') }}
                            </label>
                            <b-form-textarea
                              id="orderComment"
                              v-model="order.comments[0].comment"
                              :placeholder="$t('order.label.comment')"
                              rows="3"
                              max-rows="20"
                              @change="commitOrder()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                      </b-row>
                    </rc-overlay>
                  </b-card-text>

                  <template #footer>

                    <div class="text-right">
                      <b-button
                        v-if="order.status === 'draft'"
                        class="mr-1"
                        variant="outline-danger"
                        :disabled="loading || lockSaveButton"
                        @click="confirmDelete"
                      >
                        <feather-icon icon="Trash2Icon" />
                        {{ $t('order.button.delete') }}
                      </b-button>

                      <b-button
                        variant="primary"
                        :disabled="loading || lockSaveButton"
                        @click="fullValidation = true; saveChanges(true)"
                      >
                        {{ $t('shared.button.submit') }}
                      </b-button>
                    </div>

                  </template>
                </b-card>
              </b-col>
            </b-row>
          </b-col>

          <b-col
            cols="12"
            md="5"
          >

            <b-row class="justify-content-between h-100">
              <b-col cols="12">
                <b-card
                  :title="$t('order.label.transport-info.pickup-point-title')"
                  class="card-justify"
                >
                  <b-card-text>
                    <rc-overlay :show="loading">
                      <address-form
                        v-model="order.pickupAddress"
                        field="pickupAddress"
                        :disabled="isDisabled()"
                        :address-details-value="typeof order.pickupAddressDetails === 'number' ? order.pickupAddressDetails : null"
                        @change="commitOrder()"
                        @address-details-selected="val => { order.pickupAddressDetails = val; commitOrder() }"
                      />

                      <validation-provider
                        #default="{ errors }"
                        :name="$t('order.label.transport-info.pickup-point-title')"
                        :rules="fullValidation ? 'required' : ''"
                      >
                        <input
                          v-model="order.pickupAddress.id"
                          type="hidden"
                        >
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <validation-provider
                        v-if="order.pickupAddress.id"
                        #default="{ errors }"
                        :name="$t('order.label.transport-info.pickup-details')"
                        rules="max:160"
                      >
                        <label
                          for="pickupDetails"
                          class="mt-1"
                        >
                          {{ $t('order.label.transport-info.pickup-details') }}
                        </label>
                        <b-form-input
                          id="pickupDetails"
                          v-model="order.pickupDetails"
                          type="text"
                          maxlength="160"
                          :placeholder="$t('order.label.transport-info.pickup-details')"
                          autocomplete="off"
                          :disabled="isDisabled(statusesWhenDispatcherCanEdit, ['ROLE_DISPATCHER'])"
                          @change="commitOrder()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </rc-overlay>
                  </b-card-text>
                </b-card>
              </b-col>

              <b-col cols="12">
                <b-card
                  :title="$t('order.label.transport-info.delivery-point-title')"
                  class="card-justify"
                >
                  <b-card-text>
                    <rc-overlay :show="loading">
                      <address-form
                        v-model="order.deliveryAddress"
                        field="deliveryAddress"
                        :disabled="isDisabled()"
                        :address-details-value="typeof order.deliveryAddressDetails === 'number' ? order.deliveryAddressDetails : null"
                        @change="commitOrder()"
                        @address-details-selected="val => { order.deliveryAddressDetails = val; commitOrder() }"
                      />

                      <validation-provider
                        #default="{ errors }"
                        :name="$t('order.label.transport-info.delivery-point-title')"
                        :rules="fullValidation ? 'required' : ''"
                      >
                        <input
                          v-model="order.deliveryAddress.id"
                          type="hidden"
                        >
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <validation-provider
                        v-if="order.deliveryAddress.id"
                        #default="{ errors }"
                        :name="$t('order.label.transport-info.delivery-details')"
                        rules="max:160"
                      >
                        <label
                          for="deliveryDetails"
                          class="mt-1"
                        >
                          {{ $t('order.label.transport-info.delivery-details') }}
                        </label>
                        <b-form-input
                          id="deliveryDetails"
                          v-model="order.deliveryDetails"
                          type="text"
                          maxlength="160"
                          :placeholder="$t('order.label.transport-info.delivery-details')"
                          autocomplete="off"
                          :disabled="isDisabled(statusesWhenDispatcherCanEdit, ['ROLE_DISPATCHER'])"
                          @change="commitOrder()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </rc-overlay>
                  </b-card-text>
                </b-card>
              </b-col>

              <b-col
                v-if="differentClientInfo"
                cols="12"
              >
                <b-card
                  :title="$t('order.label.transport-info.client-data-title')"
                  class="card-justify"
                >
                  <b-card-text>
                    <rc-overlay :show="loading">
                      <address-form
                        v-model="order.customerAddress"
                        field="customerAddress"
                        :disabled="isDisabled()"
                        :address-details-value="typeof order.customerAddressDetails === 'number' ? order.customerAddressDetails : null"
                        @change="commitOrder()"
                        @address-details-selected="val => { order.customerAddressDetails = val; commitOrder() }"
                      />

                      <validation-provider
                        #default="{ errors }"
                        :name="$t('order.label.transport-info.client-data-title')"
                        :rules="fullValidation ? 'required' : ''"
                      >
                        <input
                          v-model="order.deliveryAddress.id"
                          type="hidden"
                        >
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </rc-overlay>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>

          </b-col>

        </b-row>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.min.css'
import { Polish as PolishLocale } from 'flatpickr/dist/l10n/pl'
import { empty, cloneNested, parseRequestError } from '@/helpers/helpers'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup, BFormTextarea, BInputGroup,
  BInputGroupAppend,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'
import OrderBreadcrumbs from '@/views/orders/partials/OrderBreadcrumbs.vue'
import guard from '@/guard'
import AddressForm from '@/views/orders/partials/AddressForm.vue'

export default {
  components: {
    AddressForm,
    OrderBreadcrumbs,
    BCard,
    BCardText,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,

    BFormInput,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckbox,
    BFormTextarea,

    RcOverlay,

    flatPickr,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      lockSaveButton: false,
      fullValidation: false,
      differentClientInfo: false,
      saveChangesTimout: null,
      unsavedChanges: false,

      order: cloneNested(this.$store.getters['order/getOrderData']),

      meta: {
        breadcrumb: [
          { text: 'order.orders_title', to: '/order/list' },
          { text: 'order.edit_order', active: true },
        ],
        pageTitle: 'order.edit_order',
      },
      paymentMethods: [
        {
          value: null,
          label: this.$t('shared.select'),
        },
        {
          value: 'card',
          label: this.$t('order.label.base-order-info.payment-method-card'),
        },
        {
          value: 'cash',
          label: this.$t('order.label.base-order-info.payment-method-cash'),
        },
      ],
      configDateTimePicker: {
        locale: PolishLocale,
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        disableMobile: true,
      },
      statusesWhenDispatcherCanEdit: [
        'received',
        'confirmed',
        'on_way_to_pickup_point',
        'at_pickup_point',
        'patient_pick_up',
        'on_way_to_delivery_point',
        'at_delivery_point',
        'patient_delivery',
        'patient_not_taken',
        'completed',
      ],
    }
  },
  computed: {
    orderEdited() {
      return this.$store.getters['order/getOrderData']
    },
    orderTypes() {
      return this.$store.state.order.types
    },
    me() {
      return this.$store.getters['auth/getUser']
    },
  },
  watch: {
    orderEdited: {
      deep: true,
      handler(newOrder) {
        this.order = cloneNested(newOrder)
      },
    },
  },
  beforeMount() {
    this.initOrderData()
  },
  beforeDestroy() {
    this.$store.commit('order/CLEAR_ORDER_EDITED')
  },
  methods: {
    initOrderData() {
      this.loading = true
      this.$store.dispatch('order/fetchOrderById', this.$route.params.id)
        .then(res => {
          this.loading = false

          if (this.order.status !== 'draft' && !guard.hasAtLeastOneRole(['ROLE_ADVANCED_DISPATCHER'])) {
            this.redirectToView()
          }

          this.order.pickupAddressDetails = res.data.pickupAddressDetails ? res.data.pickupAddressDetails.id : null
          this.order.deliveryAddressDetails = res.data.deliveryAddressDetails ? res.data.deliveryAddressDetails.id : null
          this.order.customerAddressDetails = res.data.customerAddressDetails ? res.data.customerAddressDetails.id : null

          this.differentClientInfo = !res.data.private && res.data.customerAddress && res.data.pickupAddress
              && ((!empty(res.data.customerAddress) ? res.data.customerAddress.id : null)
                  !== (!empty(res.data.pickupAddress) ? res.data.pickupAddress.id : null))
        })
        .catch(err => parseRequestError(err))
    },
    saveChanges(confirm = false) {
      this.fullValidation = confirm
      this.$nextTick(() => {
        this.$refs.editOrderValidation.validate()
          .then(valid => {
            if (!valid) {
              return false
            }

            this.lockSaveButton = true
            if (this.fullValidation) {
              this.loading = true
            }

            const orderData = this.prepareOrderData()

            if (confirm && orderData.status === 'draft') {
              orderData.status = 'received'
            }

            const payload = {
              id: orderData.id,
              data: orderData,
            }
            this.$store.dispatch('order/updateOrder', payload)
              .then(() => {
                window.toast.notify.success(this.$t('order.notify.edit.success_title'))
                if (confirm) {
                  this.redirectToView()
                }
              })
              .catch(err => parseRequestError(err))
              .finally(() => {
                this.loading = false
                this.lockSaveButton = false
              })

            return true
          })
      })
    },
    prepareOrderData() {
      const orderData = cloneNested(this.order)
      orderData.customerAddress = !empty(this.order.customerAddress) ? this.order.customerAddress.id : null
      orderData.pickupAddress = !empty(this.order.pickupAddress) ? this.order.pickupAddress.id : null
      orderData.deliveryAddress = !empty(this.order.deliveryAddress) ? this.order.deliveryAddress.id : null
      orderData.doctorUser = !empty(this.order.doctorUser) ? this.order.doctorUser.id : null
      if (!orderData.comments[0].comment) {
        orderData.comments = null
      }

      return orderData
    },
    commitOrder() {
      this.$store.commit('order/SET_ORDER_EDITED', this.order)
      this.unsavedChanges = true
      if (this.order.status === 'draft') {
        this.setSaveChangesTimeout()
      }
    },
    setSaveChangesTimeout() {
      clearTimeout(this.saveChangesTimout)
      this.lockSaveButton = true
      this.saveChangesTimout = setTimeout(() => {
        this.saveChanges()
      }, 2500)
    },
    isDisabled(notForStatus = [], notForRoles = []) {
      if (this.order.status === 'draft') {
        return false
      }

      if (guard.hasAtLeastOneRole(this.me.roles, ['ROLE_ADVANCED_DISPATCHER'])) {
        return false
      }

      if (notForStatus.includes(this.order.status) && guard.hasAtLeastOneRole(this.me.roles, notForRoles)) {
        return false
      }

      return true
    },
    handleDifferentClientInfo(differentClientInfo) {
      if (!differentClientInfo) {
        if (this.order.status !== 'draft' && this.order.pickupAddress) {
          this.order.customerAddress = this.order.pickupAddress
        } else {
          this.order.customerAddress = {}
        }
        this.commitOrder()
      }
    },
    handleIsPrivate() {
      if (!this.order.private) {
        this.order.paymentMethod = null
        this.order.amountGross = null
        this.commitOrder()
      } else {
        this.order.commissionedBy = null
        this.order.agreedWith = null
        this.differentClientInfo = false
        this.handleDifferentClientInfo()
      }
    },
    convertFromAnnual() {
      if (this.order.patientInfo.age.toString().length < 3) {
        if (parseInt(moment().format('YY'), 10) < this.order.patientInfo.age) {
          this.order.patientInfo.age = parseInt(moment().format('YY'), 10) - this.order.patientInfo.age + 100
        } else {
          this.order.patientInfo.age = parseInt(moment().format('YY'), 10) - this.order.patientInfo.age
        }
      } else {
        this.order.patientInfo.age = parseInt(moment().format('YYYY'), 10) - this.order.patientInfo.age
      }
      this.commitOrder()
    },
    confirmDelete() {
      this.loading = true

      this.$bvModal
        .msgBoxConfirm(
          this.$t('order.confirm-delete-draft.description'),
          {
            title: this.$t('order.confirm-delete-draft.title'),
            okTitle: this.$t('shared.yes'),
            cancelTitle: this.$t('shared.no'),
            okVariant: 'danger',
            cancelVariant: 'outline-secondary',
          },
        )
        .then(answer => {
          if (answer) {
            this.$store.dispatch('order/deleteOrder', this.order.id)
              .then(() => {
                this.$router.back()
                window.toast.notify.success(this.$t('order.confirm-delete-draft.notify-success'))
              })
          } else {
            this.loading = false
          }
        })
    },
    redirectToView() {
      this.$router.push(`/order/view/${this.$route.params.id}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-justify {
  height: calc(100% - 2rem) !important;
}
</style>
