<template>
  <div>
    <slot :events="{ inputHasChanged }">

      <v-select
        v-model="selected"
        :placeholder="$t('order.label.address.search')"
        :options="results"
        :filterable="false"
        :clearable="false"
        :get-option-label="getOptionLabel"
        @search="inputHasChanged"
        @option:selected="resultHasBeenSelected"
      >
        <span
          slot="no-options"
          class="text-center"
        >
          {{ $t('shared.write-something') }}
        </span>
      </v-select>

    </slot>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { Loader } from '@googlemaps/js-api-loader'

export default {

  name: 'GooglePlacesAutocomplete',

  components: {
    vSelect,
  },

  props: {

    value: {
      type: String,
      required: false,
      default: '',
    },

    locationTypes: {
      type: Array,
      required: false,
      default: () => ([]),
    },

  },

  data() {
    return {
      service: null,
      sessionToken: null,
      results: [],
      selected: null,
    }
  },

  beforeMount() {
    if (!this.service) {
      this.initGoogleAutoCompleteService()
    }
  },

  methods: {

    initGoogleAutoCompleteService() {
      const loader = new Loader({
        apiKey: 'AIzaSyB5zbQZqenSwG_xowvr2lQ4_HfU_ZD-CQY',
        version: 'weekly',
        language: 'pl',
        libraries: ['places', '&region=pl'],
      })
      loader
        .load()
        .then(() => {
          this.sessionToken = new window.google.maps.places.AutocompleteSessionToken()
          this.service = new window.google.maps.places.AutocompleteService()
        })
    },

    inputHasChanged(input) {
      if (!input) return

      this.service.getPlacePredictions({
        input,
        sessionToken: this.sessionToken,
      }, (predictions, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) return
        this.results = predictions
      })
    },

    resultHasBeenSelected(selected) {
      const { place_id } = selected
      const placeService = new window.google.maps.places.PlacesService(document.createElement('div'))

      placeService.getDetails({
        placeId: place_id,
        locationTypes: this.locationTypes,
      }, place => {
        this.$emit('place:changed', place)
        this.selected = null
      })
    },

    getOptionLabel(option) {
      return option.description
    },

  },

}
</script>
