<template>
  <div class="address-form">

    <div v-if="!addNew">
      <div v-if="!disabled">
        <label class="vs-input--label block">
          {{ $t('order.label.address.search') }}
        </label>
        <b-form-group>
          <v-select
            v-model="address"
            :placeholder="$t('order.label.address.search')"
            :options="suggestions"
            :disabled="loading"
            :filterable="false"
            :clearable="false"
            :no-drop="queryString.length < 3"
            :get-option-label="getOptionLabel"
            @search="fetchResults"
            @option:selected="onSelected"
          >
            <span slot="selected-option-container" />
            <span slot="no-options" />
            <template #option="option">
              <span v-if="option.name">{{ option.name }}<br></span>
              <strong v-if="option.alias">@{{ option.alias }}<br></strong>
              <span>{{ option.address }}, {{ option.zip }} {{ option.city }}</span>
            </template>
            <template #list-footer>
              <b-button
                class="btn-block rounded-0 rounded-bottom"
                variant="primary"
                @click="toggleAddNew()"
              >
                {{ $t('order.label.address.add-new') }}
              </b-button>
            </template>
          </v-select>
        </b-form-group>
      </div>

      <div v-if="!empty(value)">
        <h4>
          <span v-if="value.name">{{ value.name }} <br></span>
          <small
            v-if="value.alias"
            class="text-muted text-uppercase"
          >
            @{{ value.alias }}
          </small>
        </h4>
        <p class="card-text mb-25">
          {{ value.address }}
        </p>
        <p class="card-text mb-25">
          {{ value.zip }} {{ value.city }}
        </p>
        <div
          v-if="addressDetailsList.length > 0"
          class="mb-n1"
        >
          <label class="vs-input--label block">
            {{ $t('order.label.address.details') }}
          </label>
          <b-form-group>
            <v-select
              v-model="addressDetails"
              :options="addressDetailsList"
              :filter="searchByValue"
              :clearable="true"
              :reduce="option => option.id"
            >
              <span
                slot="selected-option"
                slot-scope="option"
              >
                {{ option.value }}
              </span>
              <template #option="option">
                {{ option.value }}
              </template>
            </v-select>
          </b-form-group>
        </div>
      </div>
    </div>

    <div v-if="addNew">

      <google-place-autocomplete
        :value="queryString"
        :location-types="['address']"
        class="pb-50"
        @place:changed="onPlaceChanged"
      />

      <validation-observer ref="newAddressValidation">
        <form
          id="createAddressForm"
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
              class="pb-50"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('address.label.name')"
                :rules="newAddressForm.searchable ? 'required|max:50' : ''"
              >
                <label for="name">
                  {{ $t('address.label.name') }}
                </label>
                <b-form-input
                  id="name"
                  v-model="newAddressForm.name"
                  :disabled="!newAddressForm.searchable"
                  type="text"
                  minlength="3"
                  maxlength="50"
                  :placeholder="$t('address.label.name')"
                  autocomplete="chrome-off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-50"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('address.label.alias')"
                :rules="newAddressForm.searchable ? 'required|max:50' : ''"
              >
                <label for="alias">
                  {{ $t('address.label.alias') }}
                </label>
                <b-form-input
                  id="alias"
                  v-model="newAddressForm.alias"
                  :disabled="!newAddressForm.searchable"
                  type="text"
                  minlength="3"
                  maxlength="50"
                  :placeholder="$t('address.label.alias')"
                  autocomplete="chrome-off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-50"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('address.label.street_number')"
                rules="required|min:3|max:50"
              >
                <label for="address">
                  {{ $t('address.label.street_number') }}
                </label>
                <b-form-input
                  id="address"
                  v-model="newAddressForm.address"
                  type="text"
                  minlength="3"
                  maxlength="50"
                  :placeholder="$t('address.label.street_number')"
                  autocomplete="chrome-off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-50"
            >
              <b-row>
                <b-col
                  cols="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('address.label.zip')"
                    rules="required|min:3|max:10"
                  >
                    <label for="zip">
                      {{ $t('address.label.zip') }}
                    </label>
                    <b-form-input
                      id="zip"
                      v-model="newAddressForm.zip"
                      type="text"
                      minlength="3"
                      maxlength="10"
                      :placeholder="$t('address.label.zip')"
                      autocomplete="chrome-off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="8"
                  class="pb-50"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('address.label.city')"
                    rules="required|min:3|max:50"
                  >
                    <label for="city">
                      {{ $t('address.label.city') }}
                    </label>
                    <b-form-input
                      id="city"
                      v-model="newAddressForm.city"
                      type="text"
                      minlength="3"
                      maxlength="50"
                      :placeholder="$t('address.label.city')"
                      autocomplete="chrome-off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="pb-1"
            >

              <b-form-checkbox
                v-model="newAddressForm.private"
                @input="handlePrivateChange"
              >
                {{ $t('order.label.address.is-private') }}
              </b-form-checkbox>

            </b-col>

            <b-col
              cols="12"
              class="pb-1"
            >

              <b-form-checkbox
                v-model="newAddressForm.searchable"
                :disabled="newAddressForm.private"
                @input="handleSearchableChange"
              >
                {{ $t('order.label.address.searchable') }}
              </b-form-checkbox>

            </b-col>

            <b-col
              cols="12"
              class="pb-50 text-right"
            >
              <b-button
                class="mr-1"
                variant="outline-danger"
                :disabled="loading"
                @click="addNew = false"
              >
                {{ $t('shared.button.cancel') }}
              </b-button>

              <b-button
                variant="primary"
                :disabled="loading"
                @click="submitForm"
              >
                {{ $t('shared.button.submit') }}
              </b-button>
            </b-col>

          </b-row>
        </form>

      </validation-observer>

    </div>
  </div>
</template>

<script>
import { cloneNested, empty, parseRequestError } from '@/helpers/helpers'
import GooglePlaceAutocomplete from '@/layouts/components/GooglePlaceAutocomplete.vue'
import vSelect from 'vue-select'
import {
  BButton, BCol, BFormCheckbox, BFormGroup, BFormInput, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    vSelect,

    ValidationProvider,
    ValidationObserver,

    BFormInput,
    BCol,
    BRow,
    BFormCheckbox,
    BFormGroup,
    BButton,

    // eslint-disable-next-line vue/no-unused-components
    GooglePlaceAutocomplete,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    addressDetailsValue: {
      type: Number,
      default: null,
    },
    field: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      addNew: false,
      address: {},
      addressDetailsList: [],
      addressDetails: null,
      newAddressForm: {},
      queryString: '',
      timeout: null,
      debounceMilliseconds: 400,
      suggestions: [],
    }
  },
  computed: {
    getDefaultAddress() {
      return cloneNested(this.$store.getters['address/getDefaultAddress'])
    },
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        if (newValue.id && newValue.id !== oldValue?.id) {
          this.fetchAddressDetails(newValue.id)
        }
      },
      immediate: true,
    },
    addressDetails: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$emit('address-details-selected', newValue)
        }
      },
    },
    addressDetailsValue: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.addressDetails = newValue
        }
      },
      immediate: true,
    },
  },
  methods: {
    empty,
    onSelected(value) {
      this.address = value
      this.$emit('change', this.address)
    },
    fetchResults(queryString) {
      this.queryString = queryString
      clearTimeout(this.timeout)

      if (queryString.length < 3) return

      this.timeout = setTimeout(() => {
        this.$store.dispatch('address/fetchFoundAddresses', { queryString })
          .then(res => {
            this.suggestions = res.data
          })
      }, this.debounceMilliseconds)
    },
    toggleAddNew() {
      this.newAddressForm = this.getDefaultAddress
      this.addNew = !this.addNew
    },
    onPlaceChanged(place) {
      this.newAddressForm = cloneNested(this.$store.getters['address/getDefaultAddress'])
      if (place.types.includes('point_of_interest') && this.newAddressForm.searchable) {
        this.newAddressForm.name += place.name
      }

      place.address_components.forEach(component => {
        if (component.types.includes('locality')) {
          this.newAddressForm.city = component.long_name
        }

        if (component.types.includes('postal_code')) {
          this.newAddressForm.zip = component.long_name
        }

        if (component.types.includes('route')) {
          this.newAddressForm.address = component.long_name + this.newAddressForm.address
        }

        if (component.types.includes('street_number')) {
          this.newAddressForm.address = `${this.newAddressForm.address} ${component.long_name}`
        }
      })
    },
    submitForm() {
      this.$refs.newAddressValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }
          this.loading = true

          this.$store.dispatch('address/addAddress', this.newAddressForm)
            .then(res => {
              this.onSelected(res.data)
              window.toast.notify.success(this.$t('address.notify.add.success_title'))
            })
            .catch(err => parseRequestError(err))
            .finally(() => {
              this.loading = false
              this.addNew = false
            })

          return true
        })
    },
    handleSearchableChange() {
      if (!this.newAddressForm.searchable) {
        this.newAddressForm.alias = ''
        this.newAddressForm.name = ''
      } else {
        this.newAddressForm.private = false
      }
    },
    handlePrivateChange() {
      if (this.newAddressForm.private) {
        this.newAddressForm.searchable = false
      }
    },
    getOptionLabel(option) {
      // eslint-disable-next-line no-prototype-builtins
      if (typeof option === 'object' && option.hasOwnProperty('name')) {
        return `${option.name}, ${option.alias} ${option.city}, ${option.address}`
      }
      return `${option.city}, ${option.address}`
    },
    fetchAddressDetails(addressId) {
      this.$store.dispatch('address/fetchAddressDetails', { addressId })
        .then(res => {
          this.addressDetailsList = res.data.items
        })
        .catch(err => parseRequestError(err))
    },
    searchByValue(options, search) {
      // Split the query into multiple parts
      const queryParts = search.toLowerCase().split(/\s+/)

      return options.filter(item => {
        // Convert the value to lowercase for case-insensitive comparison
        const valueLower = item.value.toLowerCase()
        // Check if all parts of the query are present in the value
        return queryParts.every(part => valueLower.includes(part))
      })
    },
  },
}
</script>
